.wrapper {
  width: 659px;
  height: 422px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 7.593px 25.309px 0px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeIcon {
  position: absolute;
  right: 20px;
  top: 20px;
}

.illustration {
  margin: 56px 115px 0px 115px;
}

.title {
  color: #113357;
  text-align: center;
  font-family: Sora;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.129px;
}

.message {
  color: #113357;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.129px;
  width: 455px;
}

.actions {
  padding-top: 36px;
  display: flex;
  gap: 15px;
}

.actions button {
  width: 176px;
  height: 40px;
  font-family: Poppins;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
}

.no {
  background-color: #1973ff;
  color: white;
  border: none;
}

.yes {
  border: 1.5px solid #1973ff;
  color: #1973ff;
  background-color: white;
}
