.picker {
  position: relative;
  flex-grow: 1;
}

.textLabel {
  flex: 1 1 fit-content;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.selectedOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 12px;
  background-color: white;
  border: 1px solid #dadce0;
  height: 38px;
}

.selectedOption.rounded {
  border-radius: 6px;
}

.selectedOption.dark {
  background-color: #113357;
  color: white;
  border: 1px solid #355270;
}

.selectedTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.selectedTitle .customIcon {
  margin-right: 10px;
  padding-bottom: 1px;
}

.placeholderTitle {
  composes: selectedTitle;
  color: rgba(0, 0, 0, 0.5);
}

.placeholderTitle.dark {
  color: rgba(255, 255, 255, 0.5);
}

.selectedOption:hover {
  cursor: pointer;
}

.selectedOption.disabled {
  background-color: unset;
}

.selectedOption.disabled:hover {
  cursor: unset;
}

.optionsContainer {
  z-index: 999;
  position: absolute;
  background-color: white;
  top: calc(100% + 5px);
  left: 0;
  width: max-content;
  min-width: 100%;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.3);
  padding: 8px;
}

.optionsContainer.rounded {
  border-radius: 6px;
}

.optionsContainer.positionAbove {
  top: auto;
  bottom: calc(100% + 5px);
}

.optionsList {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}

.optionsContainer.dark {
  background-color: #113357;
  color: white;
}

.option {
  padding: 0 12px;
  border-top: none;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 147px;
}

.optionTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  max-width: 100%;
}

.option.selected {
  background-color: #e6f1ff;
}

.option.selected.dark {
  background-color: #113357;
  color: white;
}

.option:hover {
  cursor: pointer;
  background-color: #e6f1ff;
}

.option.dark:hover {
  background-color: #204975;
}

.option.disabled:hover {
  cursor: not-allowed;
  background-color: unset;
}

.chevron.dark {
  filter: invert(1);
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 12px;
  background-color: white;
  border: 1px solid #dadce0;
  height: 38px;
}

.searchContainer input {
  border: none;
  outline: none;
  flex-grow: 1;
}

.noResults {
  padding: 12px;
  text-align: center;
  color: #666;
}
