.wrapper {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.title {
  padding: 0px;
  margin: 0px;
  color: #113357;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.413px;
  letter-spacing: -0.217px;
}

.header {
  display: flex;
  gap: 7px;
  margin-bottom: 17px;
}

.option {
  color: #113357;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 19.413px;
  letter-spacing: -0.217px;

  display: flex;
  flex-direction: row;
  gap: 7px;
  margin-bottom: 5px;
}
.option input {
  padding: 0px;
  margin: 0px;
}

.mondayIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.closeIcon {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  margin: 10px;
}

.closeIcon > svg {
  width: 10px;
  height: 10px;
}
