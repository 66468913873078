.filterItem {
  color: #657e9a;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 10px;
  font-size: 12px;
}

.newFilterItem {
  composes: filterItem;
  gap: 10px;
}

.newFilterItem > span {
  cursor: auto;
}

.filterItemTitle {
  color: white;
  font-weight: 500;
  text-decoration: underline;
}

.addFilterButton {
  cursor: pointer;
}

.removeFilterButton[data-isactive="true"] {
  cursor: pointer;
}
.removeFilterButton[data-isactive="true"]:hover,
.addFilterButton:hover {
  opacity: 0.5;
}

.statusOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 4px 0px 4px 0px;
  cursor: pointer;
  font-size: 12px;
}

.headerWrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.5ch;
  margin: -3px;
}

.statusOptionHeader {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.statusOptionHeader > .statusOptionTitle {
  text-overflow: clip;
}

.statusOptionTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.filterActions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 19px;
}

.statusOptionBox {
  width: 12px;
  height: 12px;
  min-width: 12px;
  border-radius: 1px;
  background-color: var(--status-color);
}

.statusOptionCheckBox {
  width: 15px;
  height: 15px;
  min-width: 15px;
  border-color: #e5e8ea;
  background-color: transparent;
}

.statusOption:hover > .statusOptionCheckBox {
  border-color: #e5e8ea;
  background-color: rgba(255, 255, 255, 0.3);
}

.statusOptionCheckBoxChecked {
  background-color: rgba(255, 255, 255, 0.3);
}
.statusOptionCheckBoxChecked:after {
  content: "";
  width: 3px;
  height: 8px;
  border: solid;
  border-color: #ffffff;
  border-width: 0 1px 1px 0;
  transform-origin: left;
  transform: translate(5px, -3px) rotate(45deg);
}

.searchContainer {
  display: grid;
  grid-template-columns: 10px 1fr;
  align-items: center;
  gap: 7px;
  background-color: #113357;
  border: 1px solid #657e9a;
  border-radius: 2px;
  max-width: 100%;
  height: 26px;
  padding-left: 6px;
}

.searchContainer > input {
  background-color: #113357;
  color: white;
  width: 100%;
  border: none;
  outline: none;

  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
}

.searchContainer > input::placeholder {
  color: #657e9a;
}

.optionsList {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 10px;
  display: flex;
  gap: 3px;
  flex-direction: column;
}

.option {
  padding-left: 8px;
  border-top: none;
  min-height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* min-width: 147px; */
  border-radius: 1px;
}

.optionTitle {
  height: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
}
/* style when the item does not have any children */
.optionTitle:not(:has(*)) {
  padding-left: 8px;
}

.andSeperator {
  color: #657e9a;
  font-size: 10px;
  cursor: auto;
  padding: 0px 10px;
}
