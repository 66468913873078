.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 100%;
}

.title {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 20px;
}

.pickerContainer {
  background: #113357;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
  outline: none;
}

.granularityTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 12px;
  color: white;
}

.granularityPickerContainer {
  background-color: #113357;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 4px;
  border-radius: 3px;
  width: 100px;
}

.granularityPickerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 5px;
  border-radius: 3px;
}

.granularityPickerRow:hover {
  background-color: #d9d9d933;
  cursor: pointer;
}

.granularityPickerRowTitle {
  flex: 1;
  color: white;
  font-size: 12px;
}

.granularityPickerCheckmark {
  padding: 0 4px;
  opacity: 0;
}

.granularityPickerRow.selected .granularityPickerCheckmark {
  opacity: 100%;
}

.addTaskButton {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 26px;
  gap: 5px;
  background-color: #113357;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 2px;
  padding: 3px 6px;
  cursor: pointer;

  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.4061755836009979px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.addTaskButton:hover {
  background-color: #d9d9d933;
}
